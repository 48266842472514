<template>
  <div>
    <v-container >
      <v-row class="my-1">
          <h2>Flight Log General</h2><v-btn class="mx-4" small color="primary" rounded @click="editGeneral">edit</v-btn>
      </v-row>
      <v-form :disabled="editingGeneral===false">
        <v-row dense>
            <v-col cols=6>
              <v-text-field label="Flight Number" v-model="flog.flight_id"></v-text-field>
            </v-col>
            <v-col cols=2>
              <v-text-field label="Customer Code" v-model="flog.customer_code" required></v-text-field>
            </v-col>
            <v-col cols=4>
              <v-text-field label="Customer Reference Number" v-model="flog.ref_number" required></v-text-field>
            </v-col>
        </v-row>
        <v-row dense>
          <v-col cols=6>
            <v-menu
              v-model="menu2"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="flightDate"
                  label="Flight Date"
                  prepend-icon="mdi-calendar"
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="flightDate"
                @input="menu2 = false"
              ></v-date-picker>
            </v-menu>
        </v-col>
        <v-col cols=6>
          <v-menu
            ref="menu"
            v-model="menu3"
            :close-on-content-click="false"
            :nudge-right="40"
            :return-value.sync="flightTime"
            transition="scale-transition"
            offset-y
            max-width="290px"
            min-width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="flightTime"
                label="Flight Time"
                prepend-icon="mdi-clock-time-four-outline"
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-time-picker
              v-model="flightTime"
              full-width
              @click:minute="$refs.menu.save(flightTime)"
            ></v-time-picker>
          </v-menu>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col>
          <v-combobox 
            v-model="flog.pilot_name"
            :items="pilotList"
            label="Pilot Name"
            dense
            hint="Note: New pilots will be added automatically"
          />
        </v-col>
        <v-col>
          <v-select
            v-model="flog.region_id"
            label="Region"
            dense
            :items="regionSelectList"
          />
        </v-col>
      </v-row>
      <v-row dense>
        <v-col cols=6>
          <v-combobox v-model="flog.drone_id" label="Drone Serial Number" dense :items="droneList" 
                      hint="Note: New drone ids will be logged, but not added to db" />
        </v-col>
        <v-col cols=6>
          <v-combobox v-model="flog.battery_id" label="Battery Serial Number" dense :items="batteryList" 
                        hint="Note: New batteries will be added to db"/>
          
        </v-col>
      </v-row>
      <v-row dense class="ma-0 pa-0">
        <v-col cols=12  class="ma-0 pa-0">
        <v-text-field label="Delivery Address" v-model="flog.delivery_address" required disabled class="ma-0 pa-0"></v-text-field>
        </v-col>
      </v-row>
      <v-row dense class="ma-0 pa-0">
        <v-col cols=4 class="ma-0 pa-0">
        <v-text-field label="City" v-model="flog.delivery_city" required disabled class="ma-0 pa-0"></v-text-field>
        </v-col>
        <v-col cols=2 class="ma-0 pa-0"><v-text-field v-model="flog.delivery_state" label="State" required disabled class="ma-0 pa-0">
        </v-text-field>
        </v-col>
        <v-col cols=2 class="ma-0 pa-0">
        <v-text-field label="Zip" v-model="flog.delivery_zip" required disabled class="ma-0 pa-0">
        </v-text-field>
        </v-col>
        <v-col cols=2 class="ma-0 pa-0">
          <v-text-field label="Latitiude" v-model="flog.delivery_lat" disabled class="ma-0 pa-0">
          </v-text-field>
        </v-col>
        <v-col cols=2 class="ma-0 pa-0">
          <v-text-field label="Longitude" v-model="flog.delivery_lon"  disabled class="ma-0 pa-0">
          </v-text-field>
        </v-col>
      </v-row>
      <v-row dense class="ma-0 pa-0">
        <v-textarea label="Notes" v-model="flog.notes" rows=1 class="ma-0 pa-0"></v-textarea>
      </v-row>
      <v-row align="center" justify="center"  class="ma-0 pa-0">
        <v-col cols=4>
          <v-btn class="mr-4" color="red" @click="cancelGeneral" :disabled="editingGeneral===false"> cancel</v-btn>
        </v-col>
        <v-col cols=4>
          <v-btn class="mr-4" color="primary" @click="saveGeneral" :disabled="editingGeneral===false"> save</v-btn>
        </v-col>
      </v-row>
    </v-form>
    </v-container>
    <v-divider></v-divider>
    <v-container class="my-4">
      <v-row>
          <h2>Flight Stats</h2><v-btn class="mx-4" small color="primary" rounded @click="editFinal">edit</v-btn>
      </v-row>
      <v-form :disabled="editingFinal===false">
        <v-row>
          <v-col cols=6>
          <v-text-field label="Take-off Weight" type="number" v-model="flog.takeoff_weight">
          </v-text-field>
          </v-col>
          <v-col cols=6>
          <v-text-field label="Distance Flown" type="number" hint="(Miles)" v-model="flog.distance_flown">
          </v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols=4>
          <v-text-field label="Maximum Altitude" type="number" v-model="flog.max_altitude">
          </v-text-field>
          </v-col>
          <v-col cols=4>
          <v-text-field label="Maximum Speed" type="number" v-model="flog.max_speed">
          </v-text-field>
          </v-col>
          <v-col cols=4>
          <v-text-field label="Battery % Used" type="number" v-model="flog.battery_used">
          </v-text-field>
          </v-col>
        </v-row>
        <v-row align="center" justify="center">
          <v-col cols=4>
            <v-btn class="mr-4" color="red" @click="cancelFinal" :disabled="editingFinal===false">cancel</v-btn>
          </v-col>
          <v-col cols=4>
            <v-btn class="mr-4" color="primary" @click="saveFinal" :disabled="editingFinal===false">save</v-btn>
          </v-col>
        </v-row>
      </v-form>
    </v-container>
    <v-divider></v-divider>
    <v-container  class="my-4">
      <v-row>
          <h2>Time Stamps</h2>
      </v-row>
      <v-row v-for="(item,idx) in timestamps" :key="item.key">
        <v-col cols=7>
        <VueCtkDateTimePicker :label="item.msg" 
                              @is-shown="timepickeropen" @is-hidden="timepickerclosed"
                              @formatted-value="manualTimeBtnClear" @validate="manualTimeBtn(idx)" 
                              formatted="HH:mm" format="HH:mm" output-format="HH:mm" :only-time=true v-model="item.value" />
        </v-col>
        <v-col cols=5>
          <v-btn width="100%" v-if="item.value===null || item.value===''" color="green" v-on:click='nowButton(idx)'>Now</v-btn>
          <v-btn width="100%" v-if="item.value!==null && item.value!==''" color="green" disabled>Now</v-btn>
        </v-col>
      </v-row>
      <v-row align="center" justify="center">
        <v-col cols=4>
          <v-btn color="blue" @click="closeFlightLog">Close Flight Log</v-btn>
        </v-col>
      </v-row>

    </v-container>
</div>
</template>

<script>
import axios from 'axios'
import { mapGetters, mapState } from "vuex"

export default {
  name: "FlightLogForm",
  components: {
  },
  props: {
    info: {},
    modified: Boolean
  },
  data() {
    return {
      date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      menu: false,
      modal: false,
      menu2: false,
      menu3: false,
      generalBackup: {},
      editingGeneral: false,
      editingFinal: false,
      flightTime : '',
      flightDate: '', 
      pilotList: [],
      pilotName: '',
      batteryList: [],
      tsidx: 0,
      tsnow: 0,
      timeValue : 0,
      flightId: 0,
      skipupdatecnt: 0,
      timepickeropenflag: false,
      flog: {
      },
      timestamps:  [
                { msg:'Time package arrived at trailer', key:'tsinitial', value:null}, 
                { msg:'Time package loaded', key:'tsloaded', value:null}, 
                { msg:'Time drone sent', key:'tssent', value:null},
                { msg:'Time drone hand-off', key:'tshandoff', value:null},
                { msg:'Time package dropped', key:'tsdropped', value:null},
                { msg:'Time drone hand-back', key:'tshandback', value:null},
                { msg:'Time drone lands', key:'tsland', value:null}
      ],
    }
  },
  computed: {
    ...mapGetters(["regionId", "accessToken", "regionList", "dxrConfig"]),
    regionSelectList: {
      get() {
        let regions = this.regionList
        let rlist = []
        regions.forEach(regionData => rlist.push(regionData["region_id"]))
        return rlist
      }
    },
    droneList: {
      get() {
          this.dronelist = []
          if (this.dxrConfig.drones !== undefined) {
            for (let i=0; i<this.dxrConfig.drones.length; i++) {
              this.dronelist.push(this.dxrConfig.drones[i].drone_id)
            }
          }
          return this.dronelist
      }
    },
    region: {
      get() {
        return this.regionId
      }
    },
    atoken: {
      get() {
        return this.accessToken
      }
    },

  },
  watch: {
    atoken(newm, oldm) {
    }
  },
  created() {
  },
  mounted() {
    this.itimer = setInterval(this.getNow, 1000)
    this.readPilotList()
    this.readRecord()
  },
  unmounted() {
    console.log('UNMOUNTED')
    if (this.tspoller!==undefined) {
      clearInterval(this.tspoller)
      clearInterval(this.itimer)
    }
  },
  methods: {
    pilotNameChanged() {

    },
    editGeneral() {
      if (this.editingFinal) {
        return
      }
      this.editingGeneral = true
      this.generalBackup = { ...this.flog }
    },
    saveGeneral() {
      this.writeRecord()
      this.editingGeneral = false
    },
    cancelGeneral() {
      if (this.generalBackup.flight_id === undefined) {
        // abort since this is a 'new' one
        this.$router.push({ path: '/pages/flightlogs'})
      }
      this.editingGeneral = false
      this.flog = { ...this.generalBackup }
    },
    editFinal() {
      if (this.editingGeneral) {
        return
      }
      this.editingFinal = true
      this.finalBackup = { ...this.flog }
    },
    saveFinal() {
      this.writeRecord()
      this.editingFinal = false
    },
    cancelFinal() {
      this.editingFinal = false
      this.flog = { ...this.finalBackup }
    },
    closeFlightLog() {
        this.$router.push({ path: '/pages/flightlogs'})
    },
    writeRecord() {
      this.skipupdatecnt = 0
      let writedata = { ...this.flog }
      for (let i=0; i<this.timestamps.length; i++) {
        let d = this.timestamps[i].value
        if (d===undefined || d===null) {
          d = ''
        }
        writedata[this.timestamps[i].key] = d
      }
      console.log("Writing...")
      console.log("flightDate: ", this.flightDate)
      console.log("flightTime: ", this.flightTime)
      let fdtime = this.flightDate+'T'+this.flightTime;
      console.log(Date.parse(fdtime))
      writedata['tsflight'] = new Date(Date.parse(fdtime)).toISOString()
      console.log(writedata)
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + this.accessToken
      axios
        .post(window.apiurl+'flightlog/'+this.$route.params.flightId, 
              JSON.stringify(writedata), {
                headers: {
                      'Content-Type': 'application/json'
                }
              })
        .then((response) => {
          console.log(response)
        })
        .catch((error) => console.log(error))
    },
    createNewFlightLogRecord(din) {
      // create new flight log, if we have a reference number, populate it
      this.editingGeneral = true

      Object.keys(din).forEach(key => din[key] === undefined && delete din[key])

      let data = {
        ref_number: '',
        flight_id: this.$route.params.flightId, 
        pilot_name: '',
        region_id: this.regionId,
        drone_id: '',
        customer_code: '',
        battery_id: '',
        delivery_address: '',
        delivery_city: '',
        delivery_zip: '',
        delivery_lon: '',
        delivery_lat: '',
        notes:'',
        distance_flown:'',
        takeoff_weight:'',
        max_altitude:'',
        max_speed:'',
        battery_used:'',
        de_order_id:''
      }
      let d = new Date()
      data['tsflight'] = d.toISOString().substr(0, 23)

      data = {
        ...data,
        ...din
      }
      return data
    },
    createFlightLogFromOrder() {
      this.editingGeneral = true
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + this.accessToken
      axios
        .get(window.apiurl+'singleorder/'+this.$route.query.de_order_id)
        .then((response) => {
          let order = response.data
          let data = this.createNewFlightLogRecord({
                      ref_number:order.reference_number, 
                      flight_id: this.$route.params.flightId,
                      region_id: order.region_id,
                      drone_id: order.drone_id,
                      customer_code: order.de_customer_code,
                      delivery_address: order.destination.full_address,
                      delivery_lat: order.destination.lat,
                      delivery_lon: order.destination.lng,
                      de_order_id: this.$route.query.de_order_id
                      })
          this.flog = data
          this.adjustTs(data)
          this.tspoller = setInterval(this.tspoll, 5000)
        })
    },

    adjustTs(data) {
            // this.flightTime = response.data[]
            var d = new Date(Date.parse(this.flog.tsflight+'Z') - (new Date()).getTimezoneOffset() * 60000)
            let d2 = d.toISOString().slice(0, -1)
            this.flightDate = d2.substr(0, 10)
            d2 = d.toISOString()
            this.flightTime = d2.substr(11, 5)

            for (let i=0; i<this.timestamps.length; i++) {
              let d = data[this.timestamps[i].key]
              if (d!==undefined) {
                this.timestamps[i].value = d
              } else {
                this.timestamps[i].value = ''
              }
            }

    },
    readRecord() {
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + this.accessToken
      axios
        .get(window.apiurl+'flightlog/'+this.$route.params.flightId)
        .then((response) => {
          if (!this.timepickeropenflag) {
            this.flog = response.data
            if (this.$route.query.de_order_id !== undefined) {
              this.createFlightLogFromOrder()
            } else if (this.flog.flight_id === undefined) {
              this.flog = this.createNewFlightLogRecord({
                ref_number:this.$route.query.ref_number,
                drone_id:this.$route.query.drone_id,
                customer_code:this.$route.query.customer_code
              })
            }
            this.adjustTs(this.flog)
            this.tspoller = setInterval(this.tspoll, 5000)
          }
        })
        .catch((error) => console.log(error))
    },

    tspoll() {
      if (this.$route.params.flightId===undefined) {
        clearInterval(this.tspoller)
      }
      if (this.skipupdatecnt<10) {
        this.skipupdatecnt += 1
        return
      }
      axios
        .get(window.apiurl+'flightlog/'+this.$route.params.flightId)
        .then((response) => {
          for (let i=0; i<this.timestamps.length; i++) {
            let d = response.data[this.timestamps[i].key]
            if (d!==undefined) {
              this.timestamps[i].value = d
            } else {
              this.timestamps[i].value = ''
            }
          }
        })
        .catch((error) => console.log(error))
    },
    readPilotList() {
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + this.accessToken
      axios.get(window.apiurl+'pilotlist')
        .then((response) => {
          this.pilotList = response.data['pilotlist']
        })
        .catch((error) => console.log(error))

      axios.get(window.apiurl+'batterylist')
        .then((response) => {
          this.batteryList = response.data['batterylist']
        })
        .catch((error) => console.log(error))
    },
    manualTimeBtnClear(idx) {
      // this.writeRecord()
    },
    timepickeropen() {
      console.log('timepicker open')
      this.timepickeropenflag = true
    },
    timepickerclosed() {
      console.log('timepicker closed')
      this.timepickeropenflag = false
    },
    manualTimeBtn(idx) {
      console.log('manualTimeBtn:', idx)
      if (this.editingGeneral) {
        this.cancelGeneral()
      }
      if (this.editingFinal) {
        this.cancelFinal()
      }
      this.writeRecord()
    },
    nowButton(idx) {
      this.timestamps[idx].value = (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(11, 5)
      this.manualTimeBtn()
    },
    getNow() {
      this.tsnow = new Date()
    },
    saveData() {
      this.$emit("save", this.info)
    },
    onModified() {
      this.$emit("modified")
    },
    cancel() {
      this.$emit("cancel")
    }
  }
}
</script>

<style scoped>
.v-card {
  margin: 0px
}
@import '~vue2-timepicker/dist/VueTimepicker.css';

.theme--light.v-input--is-disabled {
  color: red !important;
}
</style>

