<template>
    <v-card v-if="order!==undefined"> 
      <v-card-title>Order Detail</v-card-title>
      <v-card-text dense>
        <v-row dense>
            <v-col>
              <v-row dense><v-col cols=2>Customer Reference Number</v-col><v-col class="font-weight-bold">{{ order.reference_number }}</v-col></v-row>
              <v-row dense><v-col cols=2>Drone ID</v-col><v-col cols=4>
                  <v-select v-model="order.drone_id" solo small filled label="Assign Drone" dense :items="droneList" @change="dronechanged"/>
              </v-col></v-row>
              <v-row dense><v-col cols=2>Status</v-col><v-col  cols=3>
                <v-select v-model="order.status" solo small filled label="Override Status" dense :items="statuslist" @change="statuschanged"/>
                </v-col></v-row>
              <v-row dense><v-col cols=2>DE Customer Code</v-col><v-col class="font-weight-bold">{{ order.de_customer_code }}</v-col></v-row>
              <v-row dense><v-col cols=2>Store Order Number</v-col><v-col class="font-weight-bold">{{ order.store_order_number }}</v-col></v-row>
              <v-row dense><v-col cols=2>Origin Store Code</v-col><v-col class="font-weight-bold">{{ order.origin_store_code }}</v-col></v-row>
              <v-row dense><v-col cols=2>Active</v-col><v-col class="font-weight-bold">{{ order.active }}</v-col></v-row>
              <v-row dense><v-col cols=2>Region ID</v-col><v-col class="font-weight-bold">{{ order.region_id }}</v-col></v-row>
            </v-col>
        </v-row>
      </v-card-text>
      <v-card-title>Destination</v-card-title>
      <v-card-text>
        <v-row>
            <v-col> <v-btn color="primary" elevation="2" @click="downloadMission">Download Waypoints</v-btn> </v-col>
            <v-col> <v-btn color="primary" elevation="2" @click="openDeliveryMap">Open Delivery Map</v-btn> </v-col>
            <v-col cols=12>
              <v-row dense><v-col cols=2>Name</v-col>
                <v-col class="font-weight-bold">{{ order.destination.name }}
                  <v-btn v-if="order.destination.name!==''" elevation="2" small icon @click="ccopy(order.destination.name)"><v-icon>mdi-clipboard-multiple-outline</v-icon></v-btn>
                </v-col>
              </v-row>
              <v-row dense><v-col cols=2>Address</v-col>
                <v-col class="font-weight-bold">{{ order.destination.full_address }}
                  <v-btn v-if="order.destination.full_address!==''" elevation="2" small icon @click="ccopy(order.destination.full_address)"><v-icon>mdi-clipboard-multiple-outline</v-icon></v-btn>
                </v-col>
              </v-row>
              <v-row dense><v-col cols=2>Phone Number</v-col>
                <v-col cols=2 class="font-weight-bold">{{ order.destination.phone_number }}
                  <v-btn v-if="order.destination.phone_number!==''" elevation="2" small icon @click="ccopy(order.destination.phone_number)"><v-icon>mdi-clipboard-multiple-outline</v-icon></v-btn>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col cols=2>Lat/long</v-col>
                <v-col class="font-weight-bold">{{ order.destination.lat.toFixed(7) }}, {{ order.destination.lng.toFixed(7) }}
                  <v-btn elevation="2" small icon @click="copylatlng(order.destination)"><v-icon>mdi-clipboard-multiple-outline</v-icon></v-btn>
                </v-col>
              </v-row>
              <v-row dense><v-col cols=2>Distance</v-col><v-col class="font-weight-bold">{{ order.destination.distance.toFixed(2) }} Miles</v-col></v-row>
              <v-row dense><v-col cols=2>Address Line 1</v-col><v-col class="font-weight-bold">{{ order.destination.address_line_1 }}</v-col></v-row>
              <v-row dense><v-col cols=2>Address Line 2</v-col><v-col class="font-weight-bold">{{ order.destination.address_line_2 }}</v-col></v-row>
              <v-row dense><v-col cols=2>City</v-col><v-col class="font-weight-bold">{{ order.destination.city }}</v-col></v-row>
              <v-row dense><v-col cols=2>State</v-col><v-col class="font-weight-bold">{{ order.destination.state }}</v-col></v-row>
              <v-row dense><v-col cols=2>Zip</v-col><v-col class="font-weight-bold">{{ order.destination.zip }}</v-col></v-row>
            </v-col>
        </v-row>
      </v-card-text>

      <v-card-actions class="justify-center">
            <v-btn color="primary" elevation="2" @click="submit" :disabled="!modified">Update</v-btn>
            <v-btn color="error" elevation="2" @click="cancel">Cancel</v-btn>
      </v-card-actions>
    </v-card>
</template>

<script>
import axios from 'axios'
import { mapGetters, mapState } from "vuex"
import { saveAs } from 'file-saver'
import * as Sqrl from 'squirrelly'

export default {
  name: "OrderStatusForm",
  components: {
  },
  props: {
    info: {},
  },
  data() {
    return {
      modified: false,
      localorder: undefined,
      statuslist: ['Created', 'DA', 'DPU', 'DD'],
      origorder: undefined,
      templates: {}
    }
  },
  computed: {
    ...mapGetters(["fullorders", "accessToken", "dxrConfig", "regionId"]),
    atoken: {
      get() {
        return this.accessToken
      }
    },
    order: {
      get() {
        if (this.localorder===undefined && this.fullorders!==undefined) {
          this.localorder = this.fullorders.find(obj => { return obj.de_order_id === this.$route.params.de_order_id })
          this.origorder = { ...this.localorder }
        } 
        return this.localorder
      }
    },
    droneList: {
      get() {
          this.dronelist = []
          if (this.dxrConfig.drones !== undefined) {
            for (let i=0; i<this.dxrConfig.drones.length; i++) {
              this.dronelist.push(this.dxrConfig.drones[i].drone_id)
            }
          }
          this.dronelist.push('DRIVEN')
          return this.dronelist
      }
    },
  },
  watch: {
    atoken(newm, oldm) {
    }
  },
  created() {
  },
  mounted() {
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + this.accessToken
      axios
        .get(window.apiurl+'mission-templates/'+this.regionId)
        .then((response) => {
          console.log(response)
          this.missionTemplates = response.data.templates
        })
        .catch((error) => console.log(error))      
  },
  unmounted() {
  },
  methods: {
    dronechanged(s) {
      console.log('drone changed: '+s)
      console.log('this.localorder'+this.origorder.drone_id)
      if (this.localorder.status === 'Created') {
        this.localorder.status = 'DA'
      }
      this.modified = true
    },
    statuschanged(s) {
      console.log('status changed: '+s)
      console.log('this.localorder'+this.origorder.status)
      this.modified = true
    },
    async ccopy(s) {
      console.log(s)
      await navigator.clipboard.writeText(s);
    },
    async copylatlng(dest) {
        const s = dest.lat.toFixed(7) + ',' + dest.lng.toFixed(7)
        await navigator.clipboard.writeText(s);
    },
    cancel() {
      this.$router.push({ path: '/pages/flightlogs'})
    },
    downloadMission() {
      var templatedata = {lat: this.origorder.destination.lat.toFixed(7), lng:this.origorder.destination.lng.toFixed(7)}
      var result = Sqrl.render(this.missionTemplates.default_delivery.template, templatedata)
      var blob = new Blob([result], {type: "text/plain;charset=utf-8"});
      saveAs(blob, "mission-"+this.localorder.reference_number+".waypoints");
    },
    openDeliveryMap() {
      console.log(this.origorder.destination.full_address)
      this.$router.push({ name: 'dmap', params: { address: this.origorder.destination.full_address}})
    },
    submit() {
      let writedata = {
                       status:this.localorder.status, 
                       drone_id:this.localorder.drone_id,
                       de_customer_code: this.localorder.de_customer_code,
                       reference_number: this.localorder.reference_number
                       }
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + this.accessToken
      axios
        .put(window.apiurl+'orders/'+this.$route.params.de_order_id, 
              JSON.stringify(writedata), {
                headers: {
                      'Content-Type': 'application/json'
                }
              })
        .then((response) => {
          console.log(response)
          this.$router.push({ path: '/pages/flightlogs'})
        })
        .catch((error) => console.log(error))      
    }
  }
}
</script>

<style scoped>
.theme--light.v-input--is-disabled {
  color: red !important;
}
</style>

