var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[(this.$route.params.flightId!==undefined)?_c('div',[_c('FlightLogForm')],1):_vm._e(),(this.$route.params.de_order_id!==undefined)?_c('div',[_c('OrderStatusForm')],1):_vm._e(),(this.$route.params.flightId===undefined && this.$route.params.de_order_id===undefined)?_c('div',[(this.fullorders.length>0)?_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{staticClass:"elevation-2",attrs:{"headers":_vm.foheaders,"items":_vm.fullorders,"item-class":_vm.itemRowBackground,"item-key":"reference_number","sortBy":"ts_created","sort-desc":[true]},on:{"click:row":_vm.viewFullOrder},scopedSlots:_vm._u([{key:"item.viewbtn",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"color":"primary","small":""},on:{"click":function($event){return _vm.viewFullOrder(item)}}},[_vm._v("Open")])]}},{key:"item.destination.distance",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.destination.distance.toFixed(2)))])]}},{key:"item.ts_created",fn:function(ref){
var item = ref.item;
return [(item.ts_created!=='')?_c('span',[_vm._v(_vm._s(new Date(item.ts_created*1000.0).toLocaleString()))]):_vm._e()]}},{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_c('b',[_vm._v("Active Orders")])])],1)]},proxy:true}],null,false,1144512254)})],1)],1):_vm._e(),(this.orders.length>0)?_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.uaheaders,"items":_vm.orders,"item-key":"ref_number","sortBy":"da_time","sort-desc":[true]},scopedSlots:_vm._u([{key:"item.btn",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.createLog(item)}}},[_vm._v("Create Log")])]}},{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_c('b',[_vm._v("Orders to be flight-logged")])]),_c('v-spacer'),_c('v-switch',{attrs:{"dense":"","label":"Show Older Logs","color":"blue"},model:{value:(_vm.showOlder),callback:function ($$v) {_vm.showOlder=$$v},expression:"showOlder"}}),_c('v-spacer')],1)]},proxy:true}],null,false,3680504818)})],1)],1):_vm._e(),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":this.flightlogs,"item-key":"tsflight","search":_vm.search,"sortBy":"tsflight","sort-desc":[true]},scopedSlots:_vm._u([{key:"item.flight_id",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.flight_id))]),_c('span',{staticClass:"pa-1"},[_c('DeleteConfirmDialog',{attrs:{"itemname":item.flight_id},on:{"action-delete":function($event){return _vm.deleteItem(item.flight_id)}}})],1)]}},{key:"item.tsflight",fn:function(ref){
var item = ref.item;
return [(item.tsflight!=='')?_c('span',[_vm._v(_vm._s(new Date(item.tsflight+'+00:00').toLocaleString()))]):_vm._e()]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.editLog(item.flight_id)}}},[_vm._v("Edit Log")])]}},{key:"item.tscnt",fn:function(ref){
var item = ref.item;
return [(item.tscnt===7)?_c('span',[_vm._v(_vm._s(item.tscnt)+"/7")]):_vm._e(),(item.tscnt<7)?_c('span',{staticStyle:{"color":"red"}},[_vm._v(_vm._s(item.tscnt)+"/7")]):_vm._e()]}},{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_c('b',[_vm._v("Flight Logs")])]),_c('v-spacer'),_c('v-switch',{attrs:{"dense":"","label":"Show Older Logs","color":"blue"},model:{value:(_vm.showOlder),callback:function ($$v) {_vm.showOlder=$$v},expression:"showOlder"}}),_c('v-spacer'),_c('v-text-field',{staticClass:"mx-4",attrs:{"label":"Search"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)]},proxy:true}],null,false,271231805)})],1)],1),_c('br'),_c('v-row',{attrs:{"align":"center","justify":"center"}},[_c('v-col',{attrs:{"cols":"4"}},[_c('v-btn',{attrs:{"color":"red"},on:{"click":_vm.createUnlinked}},[_vm._v("Create unlinked flight log")])],1)],1)],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }