<template>
    <v-dialog v-model="dialog" width="300" >
      <template v-slot:activator="{ on, attrs }">
        <v-icon v-bind="attrs" v-on="on" small color="#a00000">mdi-trash-can-outline</v-icon>
      </template>
      <v-card align="center">
        <v-card-title class="text-h5 grey lighten-2">Confirm delete?</v-card-title>
        <v-card-text>Are you sure you want to delete <b>{{ itemname }}</b></v-card-text>
         
        <v-divider></v-divider>
        <v-card-actions >
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="confirm(false)" >No</v-btn>
          <v-btn color="red" @click="confirm(true)" >Yes</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
</template>

<script>
  export default {
    name: "DeleteConfirmDialog",
    props: {
        itemname:String
    },
    data () {
      return {
        dialog: false,
      }
    },
    methods: {
      confirm (state) {
          this.dialog = false
          if (state) {
            this.$emit('action-delete')
          }
      }
    }
  }
</script>
