<template>
  <v-container fluid>
    <div v-if='this.$route.params.flightId!==undefined'>
      <FlightLogForm></FlightLogForm>
    </div>
    <div v-if='this.$route.params.de_order_id!==undefined'>
      <OrderStatusForm></OrderStatusForm>
    </div>
    <div v-if='this.$route.params.flightId===undefined && this.$route.params.de_order_id===undefined'>
      <v-row v-if="this.fullorders.length>0">
        <v-col cols=12>
        <v-data-table
          :headers="foheaders"
          :items="fullorders"
          :item-class="itemRowBackground"
          item-key="reference_number"
          class="elevation-2"
          sortBy="ts_created"
          :sort-desc="[true]"
          @click:row="viewFullOrder"
        >
          <template v-slot:item.viewbtn="{ item }">
            <v-btn color="primary" @click="viewFullOrder(item)" small>Open</v-btn>
          </template>
          <template v-slot:item.destination.distance="{ item }">
            <span>{{ item.destination.distance.toFixed(2) }}</span>
          </template>
          <template v-slot:item.ts_created="{ item }">
            <span v-if="item.ts_created!==''">{{ new Date(item.ts_created*1000.0).toLocaleString() }}</span>
          </template>

          <template v-slot:top>
            <v-toolbar flat>
            <v-toolbar-title><b>Active Orders</b></v-toolbar-title>
            </v-toolbar>
          </template>

        </v-data-table>
        </v-col>
      </v-row>
      <v-row v-if="this.orders.length>0">
        <v-col cols=12>
        <v-data-table
          :headers="uaheaders"
          :items="orders"
          item-key="ref_number"
          class="elevation-1"
          sortBy="da_time"
          :sort-desc="[true]"
        >
          <template v-slot:item.btn="{ item }">
            <v-btn color="primary" @click="createLog(item)">Create Log</v-btn>
          </template>

          <template v-slot:top>
            <v-toolbar flat>
            <v-toolbar-title><b>Orders to be flight-logged</b></v-toolbar-title>
              <v-spacer></v-spacer>
              <v-switch v-model="showOlder" dense 
                        label="Show Older Logs" color="blue"></v-switch>
              <v-spacer></v-spacer>

            </v-toolbar>
          </template>

        </v-data-table>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols=12>
          <v-data-table
            :headers="headers"
            :items="this.flightlogs"
            item-key="tsflight"
            class="elevation-1"
            :search="search"
            sortBy="tsflight"
            :sort-desc="[true]"
          >
            <template v-slot:item.flight_id="{ item }">
              <span>{{ item.flight_id }}</span>
              <span class="pa-1"> 
                <DeleteConfirmDialog :itemname="item.flight_id" v-on:action-delete="deleteItem(item.flight_id)"></DeleteConfirmDialog> 
              </span> 
            </template>
            <template v-slot:item.tsflight="{ item }">
              <span v-if="item.tsflight!==''">{{ new Date(item.tsflight+'+00:00').toLocaleString() }}</span>
            </template>
            <template v-slot:item.status="{ item }">
              <v-btn color="primary" @click="editLog(item.flight_id)">Edit Log</v-btn>
            </template>
            <template v-slot:item.tscnt="{ item }">
              <span v-if="item.tscnt===7">{{ item.tscnt }}/7</span>
              <span style="color:red" v-if="item.tscnt<7">{{ item.tscnt }}/7</span>
            </template>
            <template v-slot:top>
              <v-toolbar flat>
              <v-toolbar-title><b>Flight Logs</b></v-toolbar-title>
              <v-spacer></v-spacer>
                <v-switch v-model="showOlder" dense label="Show Older Logs" color="blue" 
                                  ></v-switch>
              <v-spacer></v-spacer>

              <v-text-field
                v-model="search"
                label="Search"
                class="mx-4"
              ></v-text-field>
              </v-toolbar>
            </template>
          </v-data-table>
        </v-col>
      </v-row>

    <br>
    <v-row align="center" justify="center">
        <v-col cols=4>
          <v-btn color="red" @click="createUnlinked">Create unlinked flight log</v-btn>
        </v-col>
    </v-row>
    
  </div>

  </v-container>
</template>

<script>
import FlightLogForm from "../component/FlightLogForm"
import OrderStatusForm from "../component/OrderStatusForm"
import DeleteConfirmDialog from "../component/DeleteConfirmDialog"
import { mapGetters, mapState } from "vuex"
import axios from 'axios'

export default {
  components: {
    FlightLogForm,DeleteConfirmDialog,OrderStatusForm
  },
  watch: {
    sendNotification (newText, oldText) {
      if (newText !== '') {
        if (Date.now() - this.starttime > 10000) {
          this.$notification.show('Drone Express Notification', {
            body: newText,
            silent: false
            }, {})
          }
          this.$store.commit('setSendNotification','')
      }
    }
  },
  computed: {
    ...mapGetters(["orders", "fullorders", "accessToken", "regionId", "sendNotification"]),
    showOlder: {
      get () {
        return this.$store.state.showOlderFlightLogs
      },
      set (value) {
        this.$store.commit('setOlderFlightLogs', value)
      }
    }
  },
  data() {
    return {
      orderinterval:null,
      uaitems:[
        {drone_id:'DE-101', ref_number:'KR1234'}
      ],
      starttime: Date.now(),
      foheaders: [{text:'Created Time', value:'ts_created'},
                  {text:'Ref No.', value:'reference_number'},
                  {text:'Customer', value:'de_customer_code'},
                  {text:'Store Order No.', value:'store_order_number'},
                  {text:'Status', value:'status'},
                  {text:'Drone', value:'drone_id'},
                  {text:'Distance(Miles)', value:'destination.distance'},
                  {text:'View', value:'viewbtn'},
                  ],
      uaheaders: [{text:'DA Time', value:'da_time'},
                  {text:'Ref No.', value:'ref_number'},
                  {text:'Customer Code', value:'customer_code'},
                  {text:'Drone', value:'drone_id'},
                  {text:'Create Flight Log', value:'btn'}],
      flightlogs: [],
      current_orders: {},
      headers:[{text:'Date/Time', value:'tsflight'},
               {text:'Flight No.',value:'flight_id'},
               {text:'Ref No.', value:'ref_number'},
               {text:'Drone', value:'drone_id'},
               {text:'Timestamps', value:'tscnt'},
               {text:'Status', value:'status'}],
      search:''
    }
  },
  mounted() {
    console.log("MOUNTED:"+this.$route.params.flightId)
    this.startOrderUpdater()
    this.flogupdater()
    this.flitmer = setInterval(this.flogupdater, 5000)
  },
  methods: {
    itemRowBackground: function (item) {
      if (item.store_order_number!=='' && item.status === 'Created') {
        return 'row-style-g'
      }
      if (item.status === 'Created') {
        return 'row-style-r'
      }
      if (item.status === 'DA') {
        if (item.store_order_number !== '') {
          return 'row-style-g'
        } 
        return 'row-style-y'
      }
      return ''
    },
    deleteItem(flightId) {
      console.log('delete: ', flightId)
        axios.delete(window.apiurl+'flightlog/'+flightId)
        .then((response) => {
          this.flogupdater()
        })
        .catch((error) => console.log(error))
    },
    flogupdater() {
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + this.accessToken
      axios.get(window.apiurl+'flightlogs/' + this.regionId, {params:{older:this.showOlder}})
      .then((response) => {
        this.flightlogs = response.data['flightlogs']
      })
      .catch((error) => console.log(error))

    },
    editLog(flightId) {
      this.$router.push({path: '/pages/flightlogs/'+flightId})
    },
    viewFullOrder(fullorder) {
      console.log(fullorder)
      this.$router.push({path: '/pages/vieworder/'+fullorder.de_order_id})
    },
    createUnlinked(flightId) {
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + this.accessToken
        axios.post(window.apiurl+'createflightlog/'+this.regionId,
                      JSON.stringify({}), {
                headers: {'Content-Type': 'application/json'}
              })
        .then((response) => {
          let flightId = response.data['flight_id']
          if (flightId) {
            this.$router.push({ path: '/pages/flightlogs/'+flightId})
          }
        })
        .catch((error) => console.log(error))
    },
    createLog(item) {
      let ref_number = item.ref_number
      let drone_id = item.drone_id
      let customer_code = item.customer_code
      if (ref_number !== undefined) {
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + this.accessToken
        axios.post(window.apiurl+'createflightlog/'+this.regionId, 
              JSON.stringify({}), {
                headers: {'Content-Type': 'application/json'}
              })
        .then((response) => {
          let flightId = response.data['flight_id']
          console.log("thing:",item)
          if ('de_order_id' in item) {
            this.$router.push({ path: '/pages/flightlogs/'+flightId, query: {de_order_id:item.de_order_id} })
          } else if (flightId) {
            this.$router.push({ path: '/pages/flightlogs/'+flightId, query: {ref_number:ref_number, drone_id:drone_id, customer_code:customer_code} })
          }
        })
        .catch((error) => console.log(error))
      }
    },
    startOrderUpdater() {
      this.$store.dispatch('orderUpdater')
      this.orderinterval = setInterval(() => {
        this.$store.dispatch('orderUpdater')
      }, 5000)
    },
  }
}
</script>
<style>
.row-style-r {
  background-color: rgb(252, 157, 94);
}
.row-style-y {
  background-color: rgb(255, 255, 193);
}
.row-style-g {
  background-color: rgb(208, 255, 206);
}
</style>
